import { Route, Switch } from 'react-router-dom';
import {
  Archive,
  ArchiveClassReport,
  ArchiveGroupReport,
  ArchiveNetworkReport,
  Reports,
  ReportsExport,
  ReportSettingsClass,
  ReportSettingsGroup,
  ReportSettingsNetwork,
  toArchive,
  toArchiveClassReport,
  toArchiveClassReportPrint,
  toArchiveClassReportSettings,
  toArchiveClassReportId,
  toArchiveGroup,
  toArchiveGroupReport,
  toArchiveGroupReportSettings,
  toArchiveGroupReportId,
  toArchiveNetworkReport,
  toArchiveNetworkReportExport,
  toArchiveNetworkReportSettings,
  toArchiveNetworkReportId,
  toArchiveProgram,
} from 'pages';
import { ReportsRouter as OldReportsRouter } from './Archive/OldReports';

export const PagesRouterArchive = () => (
  <>
    <Switch>
      <Route
        path={[
          toArchiveNetworkReportId(),
          toArchiveGroupReportId(),
          toArchiveClassReportId(),
        ]}
      >
        <OldReportsRouter />
      </Route>

      <Route path={toArchiveClassReport()}>
        <ArchiveClassReport />
      </Route>

      <Route path={toArchiveClassReportSettings()}>
        <ReportSettingsClass isProgramActive={false} />
      </Route>

      <Route path={toArchiveGroupReport()}>
        <ArchiveGroupReport />
      </Route>

      <Route path={toArchiveGroupReportSettings()}>
        <ReportSettingsGroup isProgramActive={false} />
      </Route>

      <Route path={toArchiveNetworkReport()}>
        <ArchiveNetworkReport />
      </Route>

      <Route path={toArchiveNetworkReportSettings()}>
        <ReportSettingsNetwork isProgramActive={false} />
        <Route path={toArchiveNetworkReportExport()}>
          <ReportsExport />
        </Route>
      </Route>

      <Route path={toArchiveGroup()}>
        <Reports
          toGroupReport={toArchiveGroupReport}
          toClassReport={toArchiveClassReport}
        />
      </Route>

      <Route path={toArchiveProgram()}>
        <Archive />
      </Route>

      <Route path={toArchive()}>
        <Archive />
      </Route>
    </Switch>

    <Switch>
      <Route path={toArchiveNetworkReportExport()}>
        <ReportsExport />
      </Route>
      <Route path={toArchiveClassReportPrint()}>
        <ArchiveClassReport />
      </Route>
    </Switch>
  </>
);
