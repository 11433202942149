import { useContext } from 'react';
import { Formik, Form } from 'formik';
import pluralize from 'pluralize';

import { Class } from 'models';

import { Button, Col, Row, Modal, useFormError } from '@perts/ui';
import { ListClassesToRemove } from './ListClassesToRemove';
import TermsContext from 'components/TermsContext';

export type ClassesRemoveFormValues = {};

export type ClassesRemoveFormProps = {
  close: () => void;
  onSubmit: (values: ClassesRemoveFormValues) => void;
  classesToRemove: Class[];
};

export const ClassesRemoveForm = ({
  close,
  onSubmit,
  classesToRemove,
}: ClassesRemoveFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);

  const numParticipants = classesToRemove.reduce(
    (count, cls) => (count += cls.num_students),
    0,
  );
  const mayDelete = numParticipants === 0;

  return (
    <Modal close={close}>
      <Modal.Title className="center">{`Delete ${terms.group} ${terms.classes}`}</Modal.Title>

      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ListClassesToRemove
              classes={classesToRemove}
              mayDelete={mayDelete}
            />

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                  fullHeight
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting || !mayDelete}
                  loading={isSubmitting}
                >
                  Delete {pluralize(terms.class, classesToRemove.length, true)}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
