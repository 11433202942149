import { Form, useFormikContext } from 'formik';
import { uniqBy } from 'lodash';
import pluralize from 'pluralize';

import {
  Row,
  Col,
  HelpText,
  InfoBox,
  Input,
  Link,
  Show,
  TextField,
  Button,
} from '@perts/ui';

import { Class, ParticipantId } from 'models';
import { toGroupClassRoster, useParams } from 'pages';
import { ROSTER_ID_MIN_LENGTH, helpArticles } from 'config';
import { useTerms } from 'components/TermsContext';

import { parseStudentIds } from './helpers';

type Props = {
  cls: Class;
  hasVerifiedParticipants: boolean;
};

const RenderValidationMessage = ({
  invalidItems,
  message,
}: {
  invalidItems: ParticipantId[];
  message: React.ReactNode;
}) => {
  const uniqueIds = uniqBy(invalidItems, 'student_id');

  return (
    <Show when={uniqueIds.length}>
      <p>{message}</p>
      <ul>
        {uniqueIds.map((id, index) => (
          <li key={index}>{id.student_id}</li>
        ))}
      </ul>
    </Show>
  );
};

// eslint-disable-next-line complexity
export const ClassRosterBatchEditForm = ({
  cls,
  hasVerifiedParticipants,
}: Props) => {
  const {
    dirty,
    initialValues,
    isSubmitting,
    isValid,
    resetForm,
    setFieldValue,
    values,
    errors,
  } = useFormikContext<any>();

  const terms = useTerms();
  const { groupId, classId } = useParams();

  const {
    validIds,
    invalidIds,
    addingIds,
    removingIds,
    invalidUnique,
    invalidRosterRule,
  } = parseStudentIds(cls, initialValues.studentIds, values.studentIds);

  const hasChanges = addingIds.length > 0 || removingIds.length > 0;
  const submittable = dirty && isValid && hasChanges && validIds.length > 0;

  const label =
    cls.portal_type === 'student_id'
      ? pluralize(terms.studentIdPortalType) // "Student IDs" or "Staff IDs"
      : `Email addresses`;

  return (
    <Form>
      <Row>
        <Col cols={12}>
          <Input
            component={TextField}
            helpText={
              cls.portal_type === 'student_id' ? null : (
                <HelpText
                  articleId={helpArticles.studentEmailAdressAsRosterIds}
                >
                  Use email addresses in rosters
                </HelpText>
              )
            }
            id="studentIds"
            label={label}
            labelPlacement="top"
            multiline
            name="studentIds"
            placeholder={`Enter ${label.toLowerCase()} here.`}
            rows="6"
            disabled={isSubmitting}
            displayError={false}
          />
        </Col>
      </Row>
      <Show
        when={
          (hasVerifiedParticipants || addingIds.length > 0) &&
          !Boolean(cls.participant_pattern) &&
          !cls.roster_locked
        }
      >
        <Row>
          <Col>
            <InfoBox color="warning">
              <Row>
                <Col>
                  PERTS strongly recommends either{' '}
                  <Link to={toGroupClassRoster(groupId, classId, 'settings')}>
                    locked rosters
                  </Link>{' '}
                  or a{' '}
                  <Link to={toGroupClassRoster(groupId, classId, 'settings')}>
                    sign-on rule
                  </Link>
                  .
                </Col>
              </Row>
              <Row>
                <Col cols={6}>
                  <HelpText articleId={helpArticles.whatIsRosterLocking}>
                    What is roster locking?
                  </HelpText>
                </Col>
                <Col cols={6}>
                  <HelpText articleId={helpArticles.whatIsRosterIDRule}>
                    What is a sign-on rule?
                  </HelpText>
                </Col>
              </Row>
            </InfoBox>
          </Col>
        </Row>
      </Show>

      <Show when={submittable && (addingIds.length || removingIds.length)}>
        <Row>
          <Col cols={12}>
            <InfoBox flexDirection="row">
              <Show when={addingIds.length}>
                <span>
                  {addingIds.length} IDs will be <strong>ADDED</strong>
                </span>
              </Show>
              <Show when={addingIds.length && removingIds.length}>
                <span>&nbsp;and&nbsp;</span>
              </Show>
              <Show when={removingIds.length}>
                <span>
                  {removingIds.length} IDs will be <strong>REMOVED</strong>
                </span>
              </Show>
            </InfoBox>
          </Col>
        </Row>
      </Show>

      {errors.studentIds && (
        <Row>
          <Col>
            <InfoBox color="error">{errors.studentIds}</InfoBox>
          </Col>
        </Row>
      )}

      <Show
        when={
          invalidIds.length || invalidUnique.length || invalidRosterRule.length
        }
      >
        <Row>
          <Col cols={12}>
            <InfoBox>
              <RenderValidationMessage
                invalidItems={invalidIds}
                message={`IDs must be at least ${ROSTER_ID_MIN_LENGTH} characters with no
                  spaces. These will not be added:`}
              />

              <RenderValidationMessage
                invalidItems={invalidUnique}
                message={`These are either already on the roster or are listed twice.
                  Each new ID will only be added once:`}
              />

              <RenderValidationMessage
                invalidItems={invalidRosterRule}
                message={`These do not follow the set Student Sign-On Rule ${cls.participant_ending}.`}
              />
            </InfoBox>
          </Col>
        </Row>
      </Show>
      <Row>
        <Col cols={4}>
          <Button
            color="secondary"
            disabled={!dirty || isSubmitting}
            fullWidth
            onClick={() => resetForm()}
          >
            Discard Changes
          </Button>
        </Col>
        <Col cols={4}>
          <Button
            color="secondary"
            disabled={!submittable || isSubmitting}
            loading={isSubmitting && !values.roster_locked}
            fullWidth
            type="submit"
          >
            Save Roster
          </Button>
        </Col>
        <Col cols={4}>
          <Button
            disabled={!submittable || isSubmitting}
            loading={isSubmitting && values.roster_locked}
            fullWidth
            type="submit"
            onClick={() => setFieldValue('roster_locked', true)}
          >
            Save &amp; Lock Roster
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
