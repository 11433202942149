// Example Usage:
//
// When creating a Route:
//   import { Route } from 'react-router-dom';
//   import { toGroupMembers } from 'routes';
//   <Route path={toGroupMembers()}><PageToRender></Route>
//
// When Linking to a route:
//   import { Link } from '@perts/ui';
//   import { toGroupMembers } from 'routes';
//   <Link to={toGroupMembers('ID1234')}>View Users</Link>

import { getShortUid } from '@perts/util';

export type PagesParams = {
  classId?: string;
  filename?: string;
  groupId?: string;
  networkId?: string;
  programId?: string;
  reportId?: string;
  searchQuery?: string;
  tabKey?: string;
  template?: string;
  userId?: string;
};

// -----------------------------------------------------------------------------
//   Groups
// -----------------------------------------------------------------------------

export const toGroups = () => `/groups`;

// -----------------------------------------------------------------------------
//   Group
// -----------------------------------------------------------------------------

export const toGroup = (groupId = ':groupId') =>
  `${toGroups()}/${getShortUid(groupId)}`;

// -----------------------------------------------------------------------------
//   Group > Users
// -----------------------------------------------------------------------------

export const toGroupMembers = (groupId = ':groupId') =>
  `${toGroup(groupId)}/members`;

export const toGroupMembersAdd = (groupId = ':groupId') =>
  `${toGroupMembers(groupId)}/add`;

export const toGroupMembersRemove = (groupId = ':groupId') =>
  `${toGroupMembers(groupId)}/remove`;

export const toGroupMembersSendInvitations = (groupId = ':groupId') =>
  `${toGroupMembers(groupId)}/send-invitations`;

export const toGroupMembersManager = (groupId = ':groupId') =>
  `${toGroupMembers(groupId)}/manager`;

export const toGroupMembersLeave = (groupId = ':groupId') =>
  `${toGroupMembers(groupId)}/leave`;

export const toGroupMembersPin = (groupId = ':groupId') =>
  `${toGroupMembers(groupId)}/pin`;

// -----------------------------------------------------------------------------
//   Group > Classes
// -----------------------------------------------------------------------------

export const toGroupClasses = (groupId = ':groupId') =>
  `${toGroup(groupId)}/classes`;

export const toGroupClassesAdd = (groupId = ':groupId') =>
  `${toGroupClasses(groupId)}/add`;

export const toGroupClassesRemove = (groupId = ':groupId') =>
  `${toGroupClasses(groupId)}/remove`;

export const toGroupClassesEditUsers = (groupId = ':groupId') =>
  `${toGroupClasses(groupId)}/edit-users`;

export const toGroupClassesEditGroups = (groupId = ':groupId') =>
  `${toGroupClasses(groupId)}/edit-groups`;

export const toGroupClassesEditTargetGroup = (groupId = ':groupId') =>
  `${toGroupClasses(groupId)}/edit-target-group`;

export const toGroupClassesRosterIDRule = (groupId = ':groupId') =>
  `${toGroupClasses(groupId)}/edit-roster-id-rule`;

export const toGroupClassesRosterSignOnType = (groupId = ':groupId') =>
  `${toGroupClasses(groupId)}/edit-roster-sign-on-type`;

export const toGroupClassesRosterLocking = (groupId = ':groupId') =>
  `${toGroupClasses(groupId)}/edit-roster-locking`;

export const toGroupClassesDefaultSettings = (groupId = ':groupId') =>
  `${toGroupClasses(groupId)}/default-settings`;

// -----------------------------------------------------------------------------
//   Group > Class > Roster
// -----------------------------------------------------------------------------

export const toGroupClass = (groupId = ':groupId', classId = ':classId') =>
  `${toGroupClasses(groupId)}/${getShortUid(classId)}`;

export const toGroupClassRoster = (
  groupId = ':groupId',
  classId = ':classId',
  tabKey = ':tabKey(roster|settings|batch-edit)',
) => `${toGroupClass(groupId, classId)}/${tabKey}`;

export const toGroupClassRosterRemove = (
  groupId = ':groupId',
  classId = ':classId',
  tabKey = ':tabKey(roster|settings|batch-edit)',
) => `${toGroupClassRoster(groupId, classId, tabKey)}/remove`;

// -----------------------------------------------------------------------------
//   Group > Surveys
// -----------------------------------------------------------------------------

export const toGroupSurveys = (groupId = ':groupId') =>
  `${toGroup(groupId)}/surveys`;

export const toGroupSurveysEditMetrics = (groupId = ':groupId') =>
  `${toGroupSurveys(groupId)}/edit`;

export const toGroupSurveysEditAdvanced = (groupId = ':groupId') =>
  `${toGroupSurveys(groupId)}/settings`;

export const toGroupSurveysInstructions = (groupId = ':groupId') =>
  `${toGroupSurveys(groupId)}/instructions`;

export const toGroupClassSurveyInstructions = (
  groupId = ':groupId',
  classId = ':classId',
) => `${toGroupSurveysInstructions(groupId)}/${getShortUid(classId)}`;

export const toGroupSurveysDefaultSettings = (groupId = ':groupId') =>
  `${toGroupSurveys(groupId)}/default-settings`;

export const toGroupSurveyQRCode = (groupId = ':groupId') =>
  `${toGroupSurveys(groupId)}/qr-code`;

// -----------------------------------------------------------------------------
//   Group > Schedules
// -----------------------------------------------------------------------------

export const toGroupSchedules = (groupId = ':groupId') =>
  `${toGroup(groupId)}/schedules`;

export const toGroupSchedulesDefaultSettings = (groupId = ':groupId') =>
  `${toGroupSchedules(groupId)}/default-settings`;

export const toGroupSchedulesRemove = (groupId = ':groupId') =>
  `${toGroupSchedules(groupId)}/remove`;

// -----------------------------------------------------------------------------
//   Group > Participation
// -----------------------------------------------------------------------------

export const toGroupParticipation = (groupId = ':groupId') =>
  `${toGroup(groupId)}/participation`;

export const toGroupParticipationByParticipant = (groupId = ':groupId') =>
  `${toGroupParticipation(groupId)}/participant`;

// -----------------------------------------------------------------------------
//   Group > Reports
// -----------------------------------------------------------------------------

export const toGroupReports = (groupId = ':groupId') =>
  `${toGroup(groupId)}/reports`;

export const toGroupReport = (groupId = ':groupId') =>
  `${toGroup(groupId)}/report`;

export const toGroupReportSettings = (groupId = ':groupId') =>
  `${toGroup(groupId)}/report-settings`;

export const toClassReport = (groupId = ':groupId', classId = ':classId') =>
  `${toGroupClass(groupId, classId)}/report`;

export const toClassReportPrint = (
  groupId = ':groupId',
  classId = ':classId',
) => `${toGroupClass(groupId, classId)}/report/print`;

export const toClassReportSettings = (
  groupId = ':groupId',
  classId = ':classId',
) => `${toGroupClass(groupId, classId)}/report-settings`;

// -----------------------------------------------------------------------------
//   Home
// -----------------------------------------------------------------------------

export const toHome = (programId = ':programId') =>
  `/home/${getShortUid(programId)}`;

export const toHomeSearch = (
  programId = ':programId',
  searchQuery = ':searchQuery',
) => `${toHome(programId)}/search/${searchQuery}`;

export const toHomeNoProgram = () => '/home';

// -----------------------------------------------------------------------------
//   Home > Groups
// -----------------------------------------------------------------------------

const toHomeGroups = (programId = ':programId') =>
  `${toHome(programId)}/groups`;

export const toHomeGroupsAdd = (programId = ':programId') =>
  `${toHomeGroups(programId)}/add`;

export const toHomeGroupsCopy = (programId = ':programId') =>
  `${toHomeGroups(programId)}/copy`;

export const toHomeGroupsArchive = (
  programId = ':programId',
  groupId = ':groupId',
) => `${toHomeGroups(programId)}/${getShortUid(groupId)}/archive`;

export const toHomeGroupsCreateCourse = (
  programId = ':programId',
  groupId = ':groupId',
) => `${toHomeGroups(programId)}/${getShortUid(groupId)}/create-course`;

export const toHomeGroupsRemove = (
  programId = ':programId',
  groupId = ':groupId',
) => `${toHomeGroups(programId)}/${getShortUid(groupId)}/remove`;

// -----------------------------------------------------------------------------
//   Home > Networks
// -----------------------------------------------------------------------------

const toHomeNetworks = (programId = ':programId') =>
  `${toHome(programId)}/networks`;

export const toHomeNetworksAdd = (programId = ':programId') =>
  `${toHomeNetworks(programId)}/add`;

export const toHomeNetworksRemove = (
  programId = ':programId',
  networkId = ':networkId',
) => `${toHomeNetworks(programId)}/${getShortUid(networkId)}/remove`;

// -----------------------------------------------------------------------------
//   User
// -----------------------------------------------------------------------------

export const toUsers = () => `/users`;

export const toUser = (userId = ':userId') =>
  `${toUsers()}/${getShortUid(userId)}`;

// -----------------------------------------------------------------------------
//   Networks
// -----------------------------------------------------------------------------

export const toNetworks = () => `/networks`;

// -----------------------------------------------------------------------------
//   Network
// -----------------------------------------------------------------------------

export const toNetwork = (networkId = ':networkId') =>
  `${toNetworks()}/${getShortUid(networkId)}`;

// -----------------------------------------------------------------------------
//   Network > Members
// -----------------------------------------------------------------------------

export const toNetworkMembers = (networkId = ':networkId') =>
  `${toNetwork(networkId)}/members`;

export const toNetworkMembersAdd = (networkId = ':networkId') =>
  `${toNetworkMembers(networkId)}/add`;

export const toNetworkMembersRemove = (networkId = ':networkId') =>
  `${toNetworkMembers(networkId)}/remove`;

// -----------------------------------------------------------------------------
//   Network > Groups
// -----------------------------------------------------------------------------

export const toNetworkGroups = (networkId = ':networkId') =>
  `${toNetwork(networkId)}/groups`;

export const toNetworkGroupsAdd = (networkId = ':networkId') =>
  `${toNetworkGroups(networkId)}/add`;

export const toNetworkGroupsRemove = (networkId = ':networkId') =>
  `${toNetworkGroups(networkId)}/remove`;

// -----------------------------------------------------------------------------
//   Network > Participation
// -----------------------------------------------------------------------------

export const toNetworkParticipation = (networkId = ':networkId') =>
  `${toNetwork(networkId)}/participation`;

// -----------------------------------------------------------------------------
//   Network > Reports
// -----------------------------------------------------------------------------

export const toNetworkReports = (networkId = ':networkId') =>
  `${toNetwork(networkId)}/reports`;

export const toNetworkReport = (networkId = ':networkId') =>
  `${toNetwork(networkId)}/report`;

export const toNetworkReportExport = (networkId = ':networkId') =>
  `${toNetworkReport(networkId)}/export`;

export const toNetworkReportSettings = (networkId = ':networkId') =>
  `${toNetwork(networkId)}/report-settings`;

// -----------------------------------------------------------------------------
//   Archive
// -----------------------------------------------------------------------------

export const toArchive = () => '/archive';

export const toArchiveProgram = (programId = ':programId') =>
  `${toArchive()}/${programId}`;

export const toArchiveNetworkReport = (networkId = ':networkId') =>
  `${toArchive()}/networks/${getShortUid(networkId)}/report`;

export const toArchiveNetworkReportExport = (networkId = ':networkId') =>
  `${toArchiveNetworkReport(networkId)}/export`;

export const toArchiveNetworkReportSettings = (networkId = ':networkId') =>
  `${toArchive()}/networks/${getShortUid(networkId)}/report-settings`;

export const toArchiveGroup = (groupId = ':groupId') =>
  `${toArchive()}/groups/${getShortUid(groupId)}/reports`;

export const toArchiveGroupReport = (groupId = ':groupId') =>
  `${toArchive()}/groups/${getShortUid(groupId)}/report`;

export const toArchiveGroupReportSettings = (groupId = ':groupId') =>
  `/archive${toGroup(groupId)}/report-settings`;

export const toArchiveClassReport = (
  groupId = ':groupId',
  classId = ':classId',
) =>
  `${toArchive()}/groups/${getShortUid(groupId)}/classes/${getShortUid(
    classId,
  )}/report`;

export const toArchiveClassReportPrint = (
  groupId = ':groupId',
  classId = ':classId',
) => `/archive${toGroupClass(groupId, classId)}/report/print`;

export const toArchiveClassReportSettings = (
  groupId = ':groupId',
  classId = ':classId',
) => `/archive${toGroupClass(groupId, classId)}/report-settings`;

// For old, RServe-style reports.

export const toArchiveNetworkReportId = (
  networkId = ':networkId',
  reportId = ':reportId',
) =>
  `${toArchive()}/networks/${getShortUid(networkId)}/reports/${getShortUid(
    reportId,
  )}`;

export const toArchiveGroupReportId = (
  groupId = ':groupId',
  reportId = ':reportId',
) =>
  `${toArchive()}/groups/${getShortUid(groupId)}/reports/${getShortUid(
    reportId,
  )}`;

export const toArchiveClassReportId = (
  groupId = ':groupId',
  classId = ':classId',
  reportId = ':reportId',
) =>
  `${toArchive()}/groups/${getShortUid(groupId)}/classes/${getShortUid(
    classId,
  )}/reports/${getShortUid(reportId)}`;

export const toArchiveNetworkReportIdSettings = (
  networkId = ':networkId',
  reportId = ':reportId',
) => `${toArchiveNetworkReportId(networkId, reportId)}/settings`;

export const toArchiveGroupReportIdSettings = (
  groupId = ':groupId',
  reportId = ':reportId',
) => `${toArchiveGroupReportId(groupId, reportId)}/settings`;

export const toArchiveClassReportIdSettings = (
  groupId = ':groupId',
  classId = ':classId',
  reportId = ':reportId',
) => `${toArchiveClassReportId(groupId, classId, reportId)}/settings`;

export const toArchiveNetworkReportIdExport = (
  networkId = ':networkId',
  reportId = ':reportId',
) => `${toArchiveNetworkReportId(networkId, reportId)}/export`;
