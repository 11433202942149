import { array, boolean, object, string } from 'yup';

import { apiEntitySchemaProps } from '../Entity';

export const apiParticipantSchema = object({
  ...apiEntitySchemaProps,
  team_id: string().required().max(50),
  student_id: string().required().max(100),
  stripped_student_id: string().required().max(100),
  classroom_ids: array().of(string().required()).required().max(1),
  in_target_group: boolean().required().default(false),
  verified: boolean().required().default(true),
});
