import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { IconPrint, useBroadcast } from '@perts/ui';

import { ReportNavigationButton } from './ReportNavigationButton';
import SetupOpenResponsesModal from './SetupOpenResponsesModal';
import { channelOpenResponses } from '../../../config';
import { useCloseModal } from '../../../utils';
import { useParams } from '../../useParams';
import {
  toArchiveClassReportPrint,
  toArchiveClassReport,
  toClassReport,
  toClassReportPrint,
} from '../../routes';

type Props = {
  isProgramActive?: boolean;
};

const OpenResponsesToggle = ({ isProgramActive = true }: Props) => {
  const { pathname } = useLocation();
  const { groupId, classId } = useParams();

  const toPrint = isProgramActive
    ? toClassReportPrint
    : toArchiveClassReportPrint;
  const toReport = isProgramActive ? toClassReport : toArchiveClassReport;
  const reportRouteWithModal = toPrint(groupId, classId);
  const reportRouteWithoutModal = toReport(groupId, classId);

  const isModalVisible = pathname === reportRouteWithModal;

  const closeModal = useCloseModal({
    pathname: reportRouteWithoutModal,
    ignoreEmptyState: true,
  });

  const { broadcast } = useBroadcast(channelOpenResponses);

  useEffect(() => {
    if (!isModalVisible) {
      // Reset the global value to true when the modal closes. This is a setting
      // only relevant to the printing process. It shouldn't continue to affect
      // the page after printing. There are 5 events that should do this:
      // 1. The print window closing
      // 2. The modal closing by clicking the background
      // 3. The modal closing by pressing Esc
      // 4. Clicking the "Cancel" button in the modal
      // 5. Clicking the browser back button
      broadcast(true);
    }
  }, [broadcast, isModalVisible]);

  return (
    <>
      <ReportNavigationButton
        aria-label="Toggle open responses visibility"
        color="secondary"
        to={reportRouteWithModal}
      >
        <IconPrint />
      </ReportNavigationButton>
      <SetupOpenResponsesModal
        closeModal={closeModal}
        isModalVisible={isModalVisible}
      />
    </>
  );
};

export default OpenResponsesToggle;
