import { Button, Col, IconChevronLeft, Row, Text } from '@perts/ui';
import { useTerms } from 'components';
import { toArchiveNetworkReport, toNetworkReport, useParams } from 'pages';
import { ReportSettingsNetworkEnabledMetrics } from './ReportSettingsNetworkEnabledMetrics';
import { ReportNavigationStyled } from '../ReportNavigation/ReportNavigationStyled';
import { ReportNavigationCard } from '../ReportNavigation/ReportNavigationCard';

type Props = {
  isProgramActive?: boolean;
};

export const ReportSettingsNetwork = ({ isProgramActive = true }: Props) => {
  const { networkId } = useParams();
  const terms = useTerms();
  const toReport = isProgramActive ? toNetworkReport : toArchiveNetworkReport;

  return (
    <>
      <ReportNavigationStyled role="navigation">
        <ReportNavigationCard>
          <ReportNavigationCard.Content>
            <Row>
              <Col>
                <Button
                  color="secondary"
                  iconLeft={<IconChevronLeft />}
                  to={toReport(networkId)}
                >
                  Back to report
                </Button>
              </Col>
            </Row>
          </ReportNavigationCard.Content>
        </ReportNavigationCard>
      </ReportNavigationStyled>
      <Text as="h1">{terms.network} Report Settings</Text>
      <ReportSettingsNetworkEnabledMetrics />
    </>
  );
};
