import type {
  Schema,
  SchemaDescription,
  SchemaFieldDescription,
  SchemaObjectDescription,
} from 'yup';

const isSchemaDescription = (
  d: SchemaFieldDescription,
): d is SchemaDescription => 'tests' in d && Array.isArray(d.tests);

const isSchemaObjectDescription = (
  d: SchemaFieldDescription,
): d is SchemaObjectDescription => 'fields' in d;

export const getSchemaParam = (
  schema: Schema,
  fieldName: string,
  paramName: string,
) => {
  const desc = schema.describe();

  if (!isSchemaObjectDescription(desc)) {
    throw new Error(`Cannot get fields from non-object schema.`);
  }

  const field = desc.fields[fieldName];

  if (!field || !isSchemaDescription(field)) {
    throw new Error(`Did not find testable field "${fieldName}" in schema.`);
  }

  const test = field.tests.find((t) => t.name === paramName);

  if (!test || !test.params || !test.params[paramName]) {
    throw new Error(`Did not find testable param "${paramName}" in field.`);
  }

  return test.params[paramName];
};
