import { useContext } from 'react';
import pluralize from 'pluralize';
import { Class } from 'models';
import { InfoBox, Row, Col, Show } from '@perts/ui';
import TermsContext from 'components/TermsContext';

type ListClassesToRemoveProps = {
  classes: Class[];
  mayDelete: boolean;
};

export const ListClassesToRemove = ({
  classes,
  mayDelete,
}: ListClassesToRemoveProps) => {
  const terms = useContext(TermsContext);

  return (
    <Show when={classes.length > 0}>
      <Show when={!mayDelete}>
        <Row>
          <Col>
            <InfoBox color="warning">
              Selected {terms.classes.toLowerCase()} have{' '}
              {terms.participants.toLowerCase()} on their roster(s). Before
              deleting, remove all {terms.participants.toLowerCase()}.
            </InfoBox>
          </Col>
        </Row>
      </Show>
      <Show when={mayDelete}>
        <Row>
          <Col>
            <InfoBox color="warning">
              <span>
                Are you sure you want to <strong>delete</strong>{' '}
                {pluralize(
                  `${terms.group.toLowerCase()} ${terms.class.toLowerCase()}`,
                  classes.length,
                  true,
                )}
                ? Any associated survey data will be{' '}
                <strong>PERMANENTLY LOST</strong>.
              </span>
            </InfoBox>
          </Col>
        </Row>
      </Show>

      <Row>
        <Col>
          <ul>
            {classes.map((cls) => (
              <li key={cls.uid}>{cls.name}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </Show>
  );
};
