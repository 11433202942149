import { object, string } from 'yup';

export const apiEntitySchemaProps = {
  uid: string()
    .required() // prevents empty string
    .max(50),
  short_uid: string()
    .required() // prevents empty string
    .max(50),
  // These are datetimes, but we typically don't parse them, so they're typed
  // as strings.
  created: string().optional(),
  modified: string().optional(),
};

export const apiEntitySchema = object(apiEntitySchemaProps);
